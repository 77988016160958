/* eslint-disable */

import React from 'react';
import moment from 'moment';
import { includes } from 'lodash';

// use this method to convert any time duration that is in second, to hour:minute:second

export const secondsToHms = (d) => {
  if (!isNaN(d)) {
    d = Number(d);
    let h = Math.floor(d / 3600);
    let m = Math.floor(d % 3600 / 60);
    let s = Math.floor(d % 3600 % 60);
    let formattedSecond = s > 9 ? s : `0${s}`
    let formattedMinute = m > 9 ? m : `0${m}`
    let formattedHour = h > 9 ? h : `0${h}`
    return h !== 0 ? `${formattedHour}:${formattedMinute}:${formattedSecond}` : m !== 0 ? `${formattedMinute}:${formattedSecond}` : `00:${formattedSecond}`;
  } else {
    return d;
  }
}

// to put ellipsis in a long text and on hover it will show the full text (pass filename and length of charecter how much you want to show, as param)
export const ellipsis = (fileName, length) => {
  if (fileName && fileName.includes('_')) {
    const filteredFileName = fileName.replace(/_/g, ' ');
    return filteredFileName && filteredFileName.length > length ? <span title={filteredFileName}>{filteredFileName.slice(0, length).concat('...')}</span> : <span>{filteredFileName}</span>;
  }
  return fileName && fileName.length > length ? <span title={fileName}>{fileName.slice(0, length).concat('...')}</span> : <span>{fileName}</span>;
};

// use this method to convert any time duration that is in second, to hour:minute:second

export const hmsToSeconds = (d) => {
  const arr = d.split(':');
  let token = 0;
  const splitTime = arr.map(Number);
  if (splitTime.length === 3) {
    splitTime.forEach((el, index) => {
      if (index === 0) {
        token += el * 60 * 60;
      } else if (index === 1) {
        token += el * 60;
      } else {
        token += el;
      }
    });
  }
  if (splitTime.length === 2) {
    splitTime.forEach((el, index) => {
      if (index === 0) {
        token += el * 60;
      } else {
        token += el;
      }
    });
  }
  return token;
};

// // use this method to show number of views along with K,M etc. (eg: 2K, 1M)
// export const countViews = (views) => {
//   if (views >= 1000 && views < 1000000) {
//     const count = (views / 1000).toFixed(1);
//     const number = (`${count}`).split('.')[0];
//     const decPart = (`${count}`).split('.')[1];
//     if (decPart > 0) {
//       return `${count}K`;
//     }
//     return `${number}K`;
//   }
//   if (views >= 1000000) {
//     const count = (views / 1000000).toFixed(1);
//     const number = (`${count}`).split('.')[0];
//     const decPart = (`${count}`).split('.')[1];
//     if (decPart > 0) {
//       return `${count}M`;
//     }
//     return `${number}M`;
//   }
//   return views;
// };

export const countUploadTime = (datetime) => {
  const startTime = moment(new Date(Date.parse(datetime)), 'YYYY-MM-DD HH:mm:ss');
  const endTime = moment(new Date(), 'YYYY-MM-DD HH:mm:ss');
  const minutes = endTime.diff(startTime, 'minutes');
  const hours = endTime.diff(startTime, 'hours');
  const days = endTime.diff(startTime, 'days');
  const weeks = endTime.diff(startTime, 'weeks');
  const months = endTime.diff(startTime, 'months');
  const years = endTime.diff(startTime, 'years');
  let displayTime = '';
  if (minutes < 60) {
    displayTime = `${minutes} minutes ago`;
  }
  if (minutes >= 60) {
    displayTime = `${hours} hours ago`;
  }
  if (hours >= 24) {
    displayTime = `${days} days ago`;
  }
  if (days >= 7) {
    displayTime = `${weeks} weeks ago`;
  }
  if (weeks > 4) {
    displayTime = `${months} months ago`;
  }
  if (months > 12) {
    displayTime = `${years} years ago`;
  }
  return displayTime;
};

export const timer = (endTime) => {
  // Update the count down every 1 second
  var countDownDate = new Date(endTime).getTime();
  // Get today's date and time
  const now = new Date().getTime();

  // Find the distance between now and the count down date
  const distance = countDownDate - now;

  // Time calculations for days, hours, minutes and seconds
  let days = Math.floor(distance / (1000 * 60 * 60 * 24));
  let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  if (days.toString().length == 1) {
    days = "0" + days;
  }
  if (hours.toString().length == 1) {
    hours = "0" + hours;
  }
  if (minutes.toString().length == 1) {
    minutes = "0" + minutes;
  }
  if (distance > 0) {
    return `${days}:${hours}:${minutes}`;
  } else {
    return 0;
  }
}

export const isCharactersOrSpecialCharacter = (key) => {
  const alphabets = [
    'a', 'b', 'c', 'd', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't',
    'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'F', 'G', 'H', 'I', 'J',
    'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '`', '!', '@', '#', '$',
    '%', '^', '&', '*', '(', ')', '-', '_', '+', '+', ',', '.', '/', '?', ':', ';', '', '{', '}', '[', ']'
  ];
  if (includes(alphabets, e.key)) {
    e.preventDefault();
  }
}

// convert the hostname

export const getContentPath = (url) => {
  let contentPath = '';
  if (url) {
    if (url && url.includes('https://')) {
      contentPath = url.replace('artist.sakhaglobal.com', window.location.hostname);
    } else {
      // contentPath = window.location.protocol + '//' + window.location.hostname + url;
      contentPath = 'https://artist.sakhaglobal.com' + url;
    }
    return contentPath;
  }
}

export const checkViewPort = () => {
  const windowWidth = window.innerWidth;
  let device;
  if (windowWidth >= 320 && windowWidth <= 768) {
    device = 'web-mobile';
  } else if (windowWidth > 768 && windowWidth < 1024) {
    device = 'web-tablet';
  } else if (windowWidth >= 1024) {
    device = 'web-desktop';
  }
  return device;
};

export const getUtagDataForLogin = (userSurrogateId) => {
  window?.utag?.view({
    "tealium_event": 'view',
    "event_name": 'login', //  login or registration or subscription on other page pass "page_view"
    "page_name": `${window.location.host.replace(/com|co.za/g, '').split('.').join("")}${window.location.pathname.replaceAll("/", ":")}`,
    "page_section": `${window.location.host.replace(/com|co.za/g, '').split('.').join("")}:${window.location.pathname.split('/').pop()}`, // the last path should be passed here eg :  login or home or course;
    "page_parent_domain": `${window.location.origin}`, // need to pass the main domain
    "page_country": 'south africa', // eg - south africa
    "page_url": `${window.location.href}`,
    "page_locale": 'za',
    "page_title": `${document.querySelector("meta[property='og:title']").getAttribute('content')}`,
    "page_platform": checkViewPort(),
    "visitor_id_asset_active": userSurrogateId ? userSurrogateId : '', // user id
    "visitor_login_status": userSurrogateId ? 'loggedin' : 'loggedout',
    "page_load": 1,
    "site_version": '1.0',
    "site_type": 'web',
    "login_successful": 1 //on successful login
  });
}

export const getUtagDataForSubscription = (mediaDetails, transactionId) => {
  window?.utag?.view({
    "tealium_event": "view",
    "event_name": "subscription",
    "page_name": `${window.location.host.replace(/com|co.za/g, '').split('.').join("")}${window.location.pathname.replaceAll("/", ":")}`,
    "page_section": `${window.location.host.replace(/com|co.za/g, '').split('.').join("")}:${window.location.pathname.split('/').pop()}`, //the last path should be passed here eg :  login or home or course;
    "page_parent_domain": `${window.location.origin}`,   // need to pass the main domain
    "page_country": 'south africa', // eg - south africa
    "page_url": `${window.location.href}`,
    "page_locale": "za",
    "page_title": `${document.querySelector("meta[property='og:title']").getAttribute('content')}`,
    "page_platform": checkViewPort(),
    "visitor_id_asset_active": window.localStorage.getItem('userDetails'),  //user id
    "visitor_login_status": window.localStorage.getItem('userDetails') ? 'loggedin' : 'loggedout',
    "site_version": "1.0",
    "site_type": "web",

    "product_id": mediaDetails?.mediaId ? mediaDetails.mediaId : "",
    "product_name": mediaDetails?.mediaTitle ? mediaDetails.mediaTitle : "",// artist music name
    "product_price": "3",
    "product_category": window.sessionStorage.getItem('artistDetails') && JSON.parse(window.sessionStorage.getItem('artistDetails'))?.artistName,// artist name
    "product_sku": mediaDetails?.mediaId ? mediaDetails.mediaId : "",
    "product_type": mediaDetails?.mediaType ? mediaDetails.mediaType : "",

    "subscription_completed": 1, // on successful subscription of a product  
    "transaction_id": transactionId ? transactionId : '',
    "order_revenue": "3",
    "order_completed": 1, // on successful purchase of a product  
    "subscription_type": "daily"  // on successful subscription of a product  
  });
}

export const getSubscription = () => {
  const pageUrl = `${window.location.origin}/AicSubscribe`;
  const acrValue = window.sessionStorage.getItem('is_he_user') === 'true' ? window.sessionStorage.getItem('he_acr_value') : window.localStorage.getItem('acrValue')
  const subscriptionApi = window.sessionStorage.getItem('onmoPayConfig') && JSON.parse(window.sessionStorage.getItem('onmoPayConfig')).onmoPaySubscribeAPI.replace('$returnurl$', encodeURIComponent(pageUrl));
  const url = subscriptionApi.replace('$msisdn', acrValue);
  window.location.href = url;
}

export const saveLocationUrl = (url) => {
  window.localStorage.setItem('redirectionUrl', url)
}

// export const isMobileDevice = () => {
//   if (typeof window.orientation !== 'undefined') {
//     return true;
//   }
//   return false;
// };

// export const initDownload = (res, filename) => {
//   const blob = new Blob([res.body], { type: 'application/octet-stream' });
//   const url = window.URL.createObjectURL(blob);
//   const a = document.createElement('a');
//   a.setAttribute('id', 'downloadRJ');
//   document.body.appendChild(a);
//   a.style = 'display: none';
//   a.href = url;
//   a.download = filename;
//   a.click();
//   window.URL.revokeObjectURL(url);
// };

// export const toDataUrl = (url, callback) => {
//   const xhr = new XMLHttpRequest();
//   xhr.onload = function () {
//     const reader = new FileReader();
//     reader.onloadend = function () {
//       callback(reader.result);
//     };
//     reader.readAsDataURL(xhr.response);
//   };
//   xhr.open('GET', url);
//   xhr.responseType = 'blob';
//   xhr.send();
// };

// export const base64ToMultipartFile = (dataURI) => {
//   const byteString = atob(dataURI.split(',')[1]);
//   const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
//   const ab = new ArrayBuffer(byteString.length);
//   const ia = new Uint8Array(ab);
//   for (let i = 0; i < byteString.length; i++) {
//     ia[i] = byteString.charCodeAt(i);
//   }
//   const bb = new Blob([ab], { type: mimeString });
//   return bb;
// };

// export const isCorrectFile = (file, type) => {
//   let correctFile = false;
//   const parts = file && file.name.split('.');
//   const extension = parts && parts[parts.length - 1];
//   if (type === 'video') {
//     switch (extension.toLowerCase()) {
//       case 'm4v':
//       case 'avi':
//       case 'mpg':
//       case 'mp4':
//       case 'mkv':
//         correctFile = true;
//         break;
//       default:
//         break;
//     }
//   }
//   if (type === 'image') {
//     switch (extension.toLowerCase()) {
//       case 'png':
//       case 'jpg':
//       case 'jpeg':
//         correctFile = true;
//         break;
//       default:
//         break;
//     }
//   }
//   return correctFile;
// };

// export const isLoggedInUser = (sid) => {
//   if (window.localStorage.getItem('userDetails') !== null && JSON.parse(window.localStorage.getItem('userDetails')).userSurrogateId === sid) return true;
//   return false;
// };

// export const kFormatter = (num) => {
//   return Math.abs(num) > 999 ? `${Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1))}k` : Math.sign(num) * Math.abs(num);
// };

// export const blobToData = (blob: Blob): Promise<string> => new Promise((resolve) => {
//   const reader = new FileReader();

//   reader.onloadend = () => resolve(reader.result.toString());
//   reader.readAsDataURL(blob);
// });

// export const toDataURL = async (url: string) => {
//   const response = await fetch(url);
//   const blob = await response.blob();
//   const resData = await blobToData(blob);

//   return resData;
// };

// this converts seconds to date(format dd/mm/yyyy)
export const secToDate = (seconds) => {
  const response = new Date(seconds).toLocaleDateString();
  return response;
}

export const getRandomAlphaNumericValue = () => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < 8; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const javaDateToReadableDateAndTime = (date) => {
  var readbleDate = new Date(date);
  readbleDate = readbleDate.toDateString() + " , " + readbleDate.toLocaleTimeString(navigator.language, {
    hour: '2-digit',
    minute: '2-digit'
  });
  return readbleDate;
}
