export default {
  // The URL we're connecting to
  // hostname: 'https://mapi.dev.onedine.com:4443',
  // hostname: 'http://localhost:',
  hostname: 'https://artist.sakhaglobal.com',
  // hostname: window.location.hostname,
  ports: {
    profile: 8080,
    content: 9090,
    payment: 8686,
    notification: 9898,
  },
  // Map shortnames to the actual endpoints, so that we can use them in the middleware
  endpoints: {
    login: 'before/profile/user/login',
    register: '/api/account/register',
    reset_password: '/api/account/reset/password',
    fonts: '/api/fonts/getFontList/',
    getUserDetails: 'PortalUser/user/get/details',
    generateOtp: 'PortalUser/user/generate/otp',
    verifyOtp: 'PortalUser/user/login',
    oAuth: 'PortalAuth/oauth/token',
    googleLogin: 'PortalUser/user/social',
    updateUserDetails: 'PortalUser/user/details',
    getVideoList: 'PortalContent/video/list',
    getMusicList: 'PortalContent/music/list',
    sendfeedback: 'PortalUser/feedback',
    getTrendingMediaList: 'PortalContent/media/trending',
    getPostDetailsList: 'PortalContent/post',
    emptyPostDetailsList: 'PortalContent/post',
    getPostDetailsMedia: 'PortalContent/media/download',
    getVideoDetails: 'PortalContent/video',
    getMusicDetails: 'PortalContent/music',
    getMusicMostViewed: 'PortalContent/music/most-viewed',
    getMusicByGenre: 'PortalContent/music/by-genre',
    getMusicGenreList: 'PortalContent/genre/threshold',
    clearSuggestionswithoutGenre: 'PortalContent/music',
    clearSuggestionswithGenre: 'PortalContent/music',
    getLikeIdStatus: 'PortalContent/metrics/user-stats',
    getLikeVideoList: 'PortalContent/metrics/user-stats',
    postLikeApilist: 'PortalContent/metrics/like',
    getHistoryPlayed: 'PortalContent/history/list/Music',
    updateHistoryList: 'PortalContent/history/update',
    getCommentListVideo: 'PortalContent/comment',
    postComment: 'PortalContent/comment',
    profilePicUpload: 'PortalUser/user/image',
    getReplyList: 'PortalContent/comment/replies',
    postReply: 'PortalContent/comment',
    uploadMediaFile: 'PortalContent/media/upload',
    postData: 'PortalContent/media/post/upload',
    postExistingVideoPlaylist: 'PortalContent/playlist/Video',
    postExistingMusicPlaylist: 'PortalContent/playlist/Music',
    postCreateVideoPlaylist: 'PortalContent/playlist/Video',
    postCreateMusicPlaylist: 'PortalContent/playlist/Music',
    getAllMusicPlaylist: 'PortalContent/playlist/list/Music',
    getAllVideoPlaylist: 'PortalContent/playlist/list/Video',
    getSpecificMusicPlaylist: 'PortalContent/playlist/list/Music',
    getSpecificVideoPlaylist: 'PortalContent/playlist/list/Video',
    postFavouriteMusic: 'PortalContent/favourite/Music',
    postFavouriteVideo: 'PortalContent/favourite/Video',
    getFavouriteMusic: 'PortalContent/favourite/list/Music',
    getFavouriteVideo: 'PortalContent/favourite/list/Video',
    getAllMusicAlbum: 'PortalContent/album/list',
    getSpecificMusicAlbum: 'PortalContent/album/list',
    getRBTcallerTuneList: 'PortalContent/music/rbt/subscriber',
    postRBTcallerturnePayment: 'PortalContent/music/rbt/activate',
    deleteRBTCallertune: 'PortalContent/music/rbt/delete',
    getSuggestionswithoutGenre: 'PortalContent/music/list',
    getSuggestionsWithGenre: 'PortalContent/music/by-genre',
    deleteUploadedMedia: 'PortalContent/media',
    setOrderOfMedia: 'PortalContent/media/set/order',
    getRBTStatus: 'PortalContent/music/rbt/status',
    uploadArtistDetails: 'PortalUser/user/artist',
    getArtistInfo: 'PortalUser/user/artist',
    getRecommendedVideoList: 'PortalContent/video/recommended',
    changeMediaStatus: 'PortalContent/media/update/status',
    getGenreCategoryList: 'PortalContent/genre/details-above-threshold',
    getNotificationList: 'PortalNotification/notification',
    createAlbum: 'PortalContent/album',
    deletePlaylist: 'PortalContent/playlist/delete',
    deleteFavourite: 'PortalContent/favourite',
    getPaymentServiceOffers: 'PortalPayment/payment/serviceOffers',
    evinaAndCharge: 'PortalPayment/payment/charge',
    getSubscriptionDetails: 'PortalPayment/payment/subscription',
    unsubscribePremium: 'PortalPayment/payment/inactivate',
    getAcrValue: 'PortalUser/user/get/vodacom/acr',
    saveConsentLog: 'PortalPayment/payment/consent',
    getMediaDetails: 'PortalContent/media/get/details',
    getItemsOfPlaylist: 'PortalContent/playlist',
    getItemsOfAlbum: 'PortalContent/album',
    addLiveStream: 'PortalContent/event',
    getLiveStreamList: 'PortalContent/event/list',
    liveStreamEventsListForDashboard: 'PortalContent/event/upcoming',
    liveEventDetails: 'PortalContent/event',
    deleteLiveStream: 'PortalContent/event',
    getDropDownCommentList: 'PortalContent/comment/list',
    getCompetitionList: 'PortalContent/competition/list',
    uploadCompetition: 'PortalContent/competition/upload',
    deleteCompetition: 'PortalContent/competition',
    getCompetitionDetails: 'PortalContent/competition',
    getParticipantsList: 'PortalContent/competition/participant/list',
    uploadCompetitionWinners: 'PortalContent/competition/upload/winners',
    endCompetition: 'PortalContent/competition/end',
    getActiveUpcomingCompetitionDetails: 'PortalContent/competition/get',
    checkUserEnrollment: 'PortalContent/competition/user/enrolled',
    enterCompetition: 'PortalContent/competition/enter',
    categoryList: 'PortalContent/category/list',
    getOtpLength: 'PortalUser/user/get/config/details',
    updateSubscriptionStatus: 'PortalPayment/payment/subscription-status'
  }
};
